var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v("Entries")]),_c('v-select',{staticClass:"per-page-selector d-inline-block ml-50 mr-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.pagination.perPageOptions,"clearable":false},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}}),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$router.push({
              name: 'service-form',
              params: { service_id: 'new' },
            })}}},[_vm._v(" Add Service ")])],1)],1)],1),_c('b-table',{ref:"refServiceList",staticClass:"position-relative",attrs:{"items":_vm.services_list,"responsive":"","fields":_vm.tableColumns,"per-page":_vm.pagination.perPage,"current-page":_vm.pagination.currentPage,"primary-key":"service_id","show-empty":"","empty-text":"No matching records found"},scopedSlots:_vm._u([{key:"cell(index)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.services_list.indexOf(data.item) + 1)+" ")]}},{key:"cell(edit)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"mx-1",attrs:{"id":`service-row-${data.item.service_id}-edit-icon`,"icon":"EditIcon","size":"16"},on:{"click":function($event){return _vm.$router.push({
              name: 'service-form',
              params: { service_id: data.item.service_id },
            })}}}),_c('b-tooltip',{attrs:{"title":"Edit Service","target":`service-row-${data.item.service_id}-edit-icon`}})],1)]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}}),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalServices,"per-page":_vm.pagination.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }